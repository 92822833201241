import React, { Component } from "react";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import { Table as MaterialTable } from "@material-ui/core";
import { Link } from "react-router-dom";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// import referenceLibrary from "../../store/referenceLibrary";
// import utilityFunctions from "../../store/utilityFunctions";
export default class SearchTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFilter: "",
      tickets: [],
    };
  }

  searchTickets = () => {
    if (this.state.searchFilter.length < 3) {
      alert("Please input at least 3 character");
      return;
    }
    this.setState({ loading: true });
    const queryFilter = `&q=${this.state.searchFilter}`;

    // const ordering = "&ordering=";

    const url = config.backendServer + `tickets/contractor/?brandId=${this.props.selectedbrandID}${queryFilter}`;
    this.props
      .protectedFetch(url, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          loading: false,
          tickets: res,
          resultTxt: res.length ? "" : "No data",
        });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  handleKeyPressSearch = (e) => {
    if (e.key === "Enter") {
      this.searchTickets();
    }
  };

  render() {
    return (
      <div className={"page"}>
        <div className="border-bottom ">
          <h5>Search Tickets</h5>
        </div>
        <div className={"pt-2 row "}>
          <div className="col-5">
            <div className="input-group  mb-3">
              <input
                type="text"
                placeholder="Input ticket number"
                id={"searchFilter"}
                className={"form-control"}
                onChange={(filter) => this.setState({ searchFilter: filter.target.value })}
                onKeyUp={this.handleKeyPressSearch}
                // onInput={()=> alert("submit")}
                value={this.state.searchFilter}
                width={"70px"}
              />
              <div className="input-group-prepend" onClick={this.searchTickets} style={{ cursor: "pointer" }}>
                <span className="form-control ">
                  <i className="fa fa-search form-control-icon"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <TableContainer>
            <MaterialTable aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      lineHeight: "1rem",
                      boxSizing: "border-box",
                    }}
                  >
                    Ticket Number
                  </TableCell>
                  <TableCell
                    style={{
                      lineHeight: "1rem",
                      boxSizing: "border-box",
                    }}
                  >
                    Owner Name
                  </TableCell>
                  <TableCell
                    style={{
                      lineHeight: "1rem",
                      boxSizing: "border-box",
                    }}
                  >
                    Device Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tickets.length === 0 && <div>{this.state.resultTxt}</div>}
                {this.state.tickets.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow tabIndex={-1} key={labelId} className={"customer"}>
                      {/* Loop through head to get data order */}
                      <TableCell
                        key={index}
                        style={{
                          maxWidth: "200px",
                          wordWrap: "break-word",
                          cursor: "pointer",
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "black",
                          }}
                          to={"/warranty/" + row.id}
                        >
                          {row.ticket_number}
                        </Link>
                      </TableCell>
                      <TableCell
                        key={index}
                        style={{
                          maxWidth: "200px",
                          wordWrap: "break-word",
                        }}
                      >
                        {row.owner_name}
                      </TableCell>
                      <TableCell
                        key={index}
                        style={{
                          maxWidth: "200px",
                          wordWrap: "break-word",
                        }}
                      >
                        {row.device_name}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </MaterialTable>
          </TableContainer>
        </div>
      </div>
    );
  }
}
