import React, { Component } from "react";
import Modal from "react-modal";
import config from "../../../../config";
import moment from "moment";
import errorFunctions from "../../../../store/errorFunctions";
import DiagnosticRepair from "./DiagnosticReport/DiagnosticReport";
import QualityReport from "./QualityReport/QualityReport";
import RepairReport from "./RepairReport/RepairReport";

const buttonActionText = ["Add Diagnostic Testing Report", "Add Repair Report", "Add Quality Check Report"];
export default class Repair extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      actionIndex: 0,
      shoModalReport: false,
      isViewReport: false,
      isDisableEdit: this.props.userInfo?.user.role === "Contractor",
      reportMode: "",
      // reportCount: this.props.reportCount,
      currentReport: 0,
      devices: [],
    };
  }

  componentDidMount() {
    this.retriveDevices();
  }
  handleButtonActionClick = () => {
    this.setState({
      shoModalReport: true,
      reportMode: "new",
      currentReport: this.props.reportCount + 1,
    });
  };
  retriveDevices() {
    this.props
      .protectedFetch(config.backendServer + "devices/?brandId=" + this.props.selectedbrandID, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        let brandDevice = [];
        if (res.length > 0) {
          brandDevice = res.map((dev) => {
            return {
              id: dev.id,
              name: dev.brand.name + " " + dev.name,
            };
          });
        }

        this.setState({
          isLoading: false,
          devices: brandDevice,
          deviceId: brandDevice[0]?.id,
        });
      });
  }

  render() {
    return (
      <div>
        {this.props.isShowRepair && this.props.reportCount < 2 && (
          <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.handleButtonActionClick()}>
            {buttonActionText[this.props.reportCount + 1]}
          </button>
        )}

        <div>
          {this.props.reportCount >= 0 && (
            <div className="card m-2">
              <div className="card-header">
                {" "}
                <h6>Diagnostic Testing Report</h6>
              </div>
              <div className="card-body">
                <div className="d-flex flex-row justify-content-between">
                  <div className="p-2">{`${moment(this.props.ticketDetails?.diagnostic_report[0]?.created).format("DD MMMM YYYY HH:mm")}`}</div>
                  <div className="p-2">
                    <button
                      disabled={this.state.isDisableEdit}
                      className="btn btn-outline-primary btn-sm m-2"
                      onClick={() => {
                        this.setState({
                          shoModalReport: true,
                          reportMode: "edit",
                          currentReport: 0,
                        });
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-outline-primary btn-sm m-2"
                      onClick={() => {
                        this.setState({
                          shoModalReport: true,
                          reportMode: "view",
                          currentReport: 0,
                        });
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.props.reportCount >= 1 && (
            <div className="card m-2">
              <div className="card-header">
                {" "}
                <h6>Repair Report</h6>
              </div>
              <div className="card-body">
                <div className="d-flex flex-row justify-content-between">
                  <div className="p-2">{`${moment(this.props.ticketDetails?.repair_report[0]?.created).format("DD MMMM YYYY HH:mm")}`}</div>
                  <div className="p-2">
                    <button
                      disabled={this.state.isDisableEdit}
                      className="btn btn-outline-primary btn-sm m-2"
                      onClick={() => {
                        this.setState({
                          shoModalReport: true,
                          reportMode: "edit",
                          currentReport: 1,
                        });
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-outline-primary btn-sm m-2"
                      onClick={() => {
                        this.setState({
                          shoModalReport: true,
                          reportMode: "view",
                          currentReport: 1,
                        });
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.props.reportCount >= 2 && (
            <div className="card m-2">
              <div className="card-header">
                {" "}
                <h6>Quality Check Report</h6>
              </div>
              <div className="card-body">
                <div className="d-flex flex-row justify-content-between">
                  <div className="p-2">{`${moment(this.props.ticketDetails?.quality_check_report[0]?.created).format("DD MMMM YYYY HH:mm")}`}</div>
                  <div className="m-2">
                    <button
                      disabled={this.state.isDisableEdit}
                      className="btn btn-outline-primary btn-sm m-2"
                      onClick={() => {
                        this.setState({
                          shoModalReport: true,
                          reportMode: "edit",
                          currentReport: 2,
                        });
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-outline-primary btn-sm m-2"
                      onClick={() => {
                        this.setState({
                          shoModalReport: true,
                          reportMode: "view",
                          currentReport: 2,
                        });
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
          isOpen={this.state.shoModalReport}
          contentLabel={"Set as Out of Warranty"}
          className={"input-modal"}
          //   className="confirm-modal confirm-modal-ticket"
        >
          {this.state.currentReport === 0 && (
            <DiagnosticRepair
              device={this.props.device}
              employeeList={this.props.employeeList}
              closeModal={() => this.setState({ shoModalReport: false, reportMode: "" })}
              ticketId={this.props.ticketId}
              protectedFetch={this.props.protectedFetch}
              reportContent={this.props.ticketDetails.diagnostic_report[0]}
              reportMode={this.state.reportMode}
              retrieveWarranty={() => this.props.retrieveWarranty()}
              setDefaultTab={() => this.props.setDefaultTab()}
            />
          )}
          {this.state.currentReport === 1 && (
            <RepairReport
              employeeList={this.props.employeeList}
              closeModal={() => this.setState({ shoModalReport: false, reportMode: "" })}
              ticketId={this.props.ticketId}
              protectedFetch={this.props.protectedFetch}
              reportContent={this.props.ticketDetails.repair_report[0]}
              reportMode={this.state.reportMode}
              retrieveWarranty={() => this.props.retrieveWarranty()}
              devices={this.state.devices}
              selectedbrandID={this.props.selectedbrandID}
              setDefaultTab={() => this.props.setDefaultTab()}
              device={this.props.device}
            />
          )}
          {this.state.currentReport === 2 && (
            <QualityReport
              employeeList={this.props.employeeList}
              closeModal={() => this.setState({ shoModalReport: false, reportMode: "" })}
              ticketId={this.props.ticketId}
              protectedFetch={this.props.protectedFetch}
              reportContent={this.props.ticketDetails.quality_check_report[0]}
              reportMode={this.state.reportMode}
              retrieveWarranty={() => this.props.retrieveWarranty()}
              setDefaultTab={() => this.props.setDefaultTab()}
            />
          )}
        </Modal>
      </div>
    );
  }
}
