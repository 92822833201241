import React, { Component } from "react";
import utilityFunctions from "../../../../store/utilityFunctions";
import config from "../../../../config";
import moment from "moment";
// const userInfo = utilityFunctions.getCookie("userInfo");

export default class DeviceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      downloadedText: "",
    };
  }

  downloadFile = (ticketNumber) => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + `user_device/${this.props.details?.user_device?.id}/download_proof/`, "GET", null, (res) => {
        return res; // Do everything in the 'then'
      })
      .then((res) => {
        if (res.ok) {
          res.blob().then((res) => {
            const blob = res;
            blob.name = "Purchase__" + ticketNumber;
            utilityFunctions.downloadFileAutomatically(blob, res.type);
          });
        }
        this.setState({ loading: false, downloadedText: "File is downloaded" });
      })
      .catch((err) => {
        console.log("fail", err);
        this.setState({ loading: false });
      });
  };
  render() {
    const details = this.props.details || {};

    //tunggu di bikin sama BE
    // const isRoleServiceTeam = userRole === "Service";
    // const isRoleAdmin = userRole === "Admin";

    return (
      <div className="card">
        <h6 className={"card-header"}>Device Details</h6>
        <div className="card-body">
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>Serial Number: </span>
            <span>{details?.user_device?.serial_number}</span>
          </div>

          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>Device Name (as per customer): </span>
            <span>{details?.user_device?.device.name}</span>
          </div>
          {details?.ticket_device_accessories?.length > 0 && (
            <div className={"single-warranty-details-item"}>
              <span className={"single-warranty-details-label"}>Items Received: </span>
              <ul>
                {details?.ticket_device_accessories?.map((item, i) => {
                  return <li key={i}>{`${item.accessory_name} (${item.quantity})`}</li>;
                })}
              </ul>
            </div>
          )}
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>Seller Details: </span>
            <span>{details?.user_device?.seller_details}</span>
          </div>
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>Store Location: </span>
            <span>{details?.user_device?.store_location}</span>
          </div>
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>Date of purchase: </span>
            <span>{moment(details?.user_device?.date_of_purchase).format("DD/MM/YYYY")}</span>
          </div>
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>Date since purchased : </span>
            <span>{`${moment(details?.created).diff(moment(details?.user_device?.date_of_purchase), "days")} days`}</span>
          </div>
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>Has proof of purchase?: </span>
            <span>{details?.user_device?.proof_of_purchase ? "Yes" : "No"}</span>
          </div>
          {/* If no proof of purchase, show purchase text */}
          {!details?.user_device?.proof_of_purchase && (
            <div className={"single-warranty-details-item"} data-testid="purchase-info">
              <span className={"single-warranty-details-label"}>Purchase info: </span>
              <span>{details?.user_device?.proof_of_purchase_text || "Empty"}</span>
            </div>
          )}
          {/* If proof of purchase, button to download */}
          {details?.user_device?.proof_of_purchase && (
            <div data-testid="download-file">
              <button
                className="k-btn k-btn-outline-primary-full"
                onClick={() => this.downloadFile(details?.ticket_number)}
                style={{ margin: "10px 0" }}
              >
                Download Proof of Purchase
              </button>
              <div>
                <small className="text-info">{this.state.downloadedText}</small>
              </div>
            </div>
          )}
          <div className={"single-warranty-details-item"}>
            <div className={"single-warranty-details-label"}>Problem details:</div>
            <ul>
              {details?.reason?.map(function (item, i) {
                return <li key={i}>{item}</li>;
              })}
            </ul>
          </div>
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>System Error: </span>
            <span>{details?.system_error || ""}</span>
          </div>
          {details?.other_issue && (
            <div className={"single-warranty-details-item"}>
              <span className={"single-warranty-details-label"}>Other problem details: </span>
              <span>{details?.other_issue}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
