/* eslint-disable react/prop-types */
import { Table as MaterialTable } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React, { Component } from "react";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import referenceLibrary from "../../store/referenceLibrary";

import "./Table.css";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "./Table.css";

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    statusSelectors,
    filterStatus,
    employee,
    filterAssignTo,
    filterServiceCenter,
    assignedTo,
    statusSelector,
    serviceCenter,
    optionServiceCenterHeader,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ backgroundColor: "#F4F5F7" }}>
      <TableRow>
        {headCells.map((headCell, i) => {
          return (
            <TableCell
              key={i}
              sortDirection={orderBy === headCell.filter ? order : false}
              style={{
                lineHeight: "1rem",
                boxSizing: "border-box",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.filter}
                direction={orderBy === headCell.filter ? order : "asc"}
                onClick={createSortHandler(headCell)}
                style={{
                  fontSize: "12px",
                  color: "#777777",
                }}
              >
                {headCell.label}
              </TableSortLabel>
              {headCell.id === "status" && (
                <div>
                  <select
                    name="status"
                    id="status"
                    className="form-select"
                    style={{ width: "100px", fontSize: "smaller" }}
                    onChange={(e) => filterStatus(e.target.value)}
                    value={statusSelector}
                  >
                    <option value={0}>All</option>
                    {statusSelectors?.map((val, i) => {
                      return (
                        <option key={i} value={val?.id}>
                          {val?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              {headCell.id === "assigned_to" && (
                <div>
                  <select
                    name="assigned_to"
                    id="assigned_to"
                    className="form-select"
                    style={{ width: "100px", fontSize: "smaller" }}
                    value={assignedTo}
                    onChange={(e) => filterAssignTo(e.target.value)}
                  >
                    <option value={0}>All</option>
                    {employee?.map((val, i) => {
                      return (
                        <option key={i} value={val?.id}>
                          {val?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              {headCell.id === "service_center" && (
                <div>
                  <select
                    name="service_center"
                    id="service_center"
                    className="form-select"
                    style={{ width: "100px", fontSize: "smaller" }}
                    value={serviceCenter}
                    onChange={(e) => filterServiceCenter(e.target.value)}
                  >
                    <option value={0}>All</option>
                    {optionServiceCenterHeader?.map((val, i) => {
                      return (
                        <option key={i} value={val?.value}>
                          {val?.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      employee: null,
      curEmployeeName: "",
      showModal: false,
      showModalResetPassword: false,
      ticketID: 0,
      employeeID: 0,
      emailForReset: "",
      statusTicketType: "",
      modalStatus: false,
      showDueDateModal: false,
      dueDateReason: "",
      selecteeTicket: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ page: 0 });
    }
  }

  handleRequestSort = (event, headcell) => {
    this.props.onOrder(this.props.order === "asc" ? "desc" : "asc");
    this.props.onOrderBy(headcell.filter);
    this.props.pageNumberChg(1);

    const orderText = this.props.order !== "asc" ? headcell.filter : "-" + headcell.filter;
    const statusText = this.props.statusSelectors.find((x) => x.id === parseInt(this.props.statusSelector))?.name || "";
    this.props.retrieveWarranty(1, null, this.props.assignedTo, statusText, orderText);
  };
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
    this.props.pageNumberChg(newPage + 1);
    const statusText = this.props.statusSelectors.find((x) => x.id === parseInt(this.props.statusSelector))?.name || "";
    const orderText = this.props.order === "asc" ? this.props.orderBy : "-" + this.props.orderBy;
    this.props.retrieveWarranty(newPage + 1, this.props.rowsPerPage, this.props.assignedTo, statusText, orderText);
  };
  handleChangeRowsPerPage = (event) => {
    const rows = parseInt(event.target.value, 10);
    this.setState({
      page: 0,
    });
    this.props.rowsPerPageChg(rows);
    const statusText = this.props.statusSelectors.find((x) => x.id === parseInt(this.props.statusSelector))?.name || "";
    const orderText = this.props.order === "asc" ? this.props.orderBy : "-" + this.props.orderBy;
    this.props.retrieveWarranty(null, rows, this.props.assignedTo, statusText, orderText);
  };

  handleDropDownAssignTo = (e, id) => {
    let ticketID = id;
    const employeeName = e.label;
    let employeeID = e.value;
    if (employeeID) this.setState({ showAssignTo: true, employeeID, ticketID, curEmployeeName: employeeName });
  };

  handleSubmitAssign = (event) => {
    event.preventDefault();
    let payload = {
      assigned_to: this.state.employeeID,
    };

    this.props
      .protectedFetch(config.backendServer + `ticket/${this.state.ticketID}/assign/`, "PATCH", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        this.setState({ showAssignTo: false });
        this.props.retrieveWarranty();
      })
      .catch(() => {
        console.log("fail");
        this.setState({ showAssignTo: false });
      });
  };

  handleDueDateChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };
  changeDueDate() {
    if (!this.state.showDueDateModal) return;
    const data = {
      reason: "",
      due_date: this.state.due_date,
    };

    //ticket/:id/set-due-date/
    const url = `${config.backendServer}ticket/${this.state.ticketID}/set-due-date/`;
    this.props
      .protectedFetch(url, "PUT", data, errorFunctions.checkResponseThenJson)
      .then(() => {
        this.setState({
          dueDateReason: "",
          showDueDateModal: false,
        });
        this.props.retrieveWarranty(1, 100);
      })
      .catch((error) => {
        // Handle the error or show an error message
        alert(url + "  " + data);
        console.log(error?.response?.data?.non_field_errors?.[0] || error?.response?.data?.serial_number?.[0] || "Something went wrong.. try later");
        this.setState({ isLoading: false, showDueDateModal: false });
      });
  }

  handleStatusTicketTypeModal = (e, id) => {
    e.preventDefault();
    this.setState({
      modalStatus: true,
      statusTicketType: e.target.value,
      ticketID: id,
    });
  };

  handleChangeStatusTicketType = (e) => {
    e.preventDefault();

    let payload = {
      ticket_type: this.state.statusTicketType,
    };
    this.props
      .protectedFetch(config.backendServer + `ticket/${this.state.ticketID}/change-type/`, "PATCH", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        this.props.retrieveWarranty(1, 100);
        this.setState({ modalStatus: false });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ modalStatus: false });
      });
  };

  renderTicketType = (status, value) => {
    return value;
  };

  handleCancel = () => {
    this.props.reloadMini(this.props.selectedbrandID);
    this.setState({
      modalStatus: false,
    });
  };

  handleRowClick = (id) => {
    const history = createBrowserHistory({ forceRefresh: true });
    history.push(`/customer-detail/${id}`);
    // alert("row");
  };
  handleSubmitServiceCenter = (id) => {
    // e.preventDefault();
    const payload = { service_center: id || this.state.serviceCenterId };
    this.props
      .protectedFetch(config.backendServer + `ticket/${this.state.ticketID}/service_center/`, "PATCH", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        this.setState({ modalServiceCenter: false });
      })
      .catch(() => {
        console.log("fail");
      });
  };
  handleDropDownServiceCenterCentral = (e) => {
    // const tempSelectedServiceCenter = this.props.serviceCenters.find((x) => x.id === parseInt(e.target.value));
    if (e.value !== this.state.serviceCenterId) {
      this.setState({ serviceCenterCentralId: e.value, newServiceCenter: true });
      const selectedServiceCenterOption = this.props.serviceCenterCentrals
        ?.find((x) => x.id == e.value)
        ?.service_centers?.map((s) => {
          return { value: s.id, label: s.name };
        });

      this.setState({
        optionServiceCenters: selectedServiceCenterOption,
        selectedServiceCenters: this.props.serviceCenters?.find((x) => x.name == e.label)?.service_centers,
      });
      this.handleSubmitServiceCenterCentral(e.value);
    }
  };
  handleDropDownServiceCenter = (e) => {
    // const tempSelectedServiceCenter = this.props.serviceCenters.find((x) => x.id === parseInt(e.target.value));
    if (e.value !== this.state.serviceCenterId) {
      this.setState({ serviceCenterId: e.value, newServiceCenter: true });

      this.handleSubmitServiceCenter(e.value);
    }
  };
  handleSubmitServiceCenterCentral = (id) => {
    // e.preventDefault();
    const payload = { service_center_central: id || this.state.serviceCenterCentralId };
    this.props
      .protectedFetch(
        config.backendServer + `ticket/${this.state.ticketID}/service_center_central/`,
        "PATCH",
        payload,
        errorFunctions.checkResponseThenJson
      )
      .then(() => {
        this.setState({ modalServiceCenter: false, serviceCenterCentralId: payload.service_center_central });
        //mini reload
      })
      .catch(() => {
        console.log("fail");
      });
  };

  getStatusProperty = (status) => {
    let res = "";
    const pending = [
      "Awaiting confirmation / Pending Approval",
      "Pending return label to be sent",
      "Pending Customer to Download Return Label",
      "Pending receipt of goods",
      "Pending Repair",
      "Awaiting parts",
      "approved pending payment of service charge",
      "approved - pending acceptance of assessment fee",
      "Assessment Fee Paid - Pending Return Label to be Sent",
      "Assessment Fee Accepted - Pending Paying",
      "Quote Sent to customer pending acceptance",
      "Quote Accepted pending payment",
      "Goods received pending refund",
      "replacement sent",
      "Spare Parts Approved, Pending Dispatch",
      "Return Label Link Sent, Pending Receipt of Goods",
      "Repair complete, goods sent out",
    ].map((element) => element.toLowerCase());

    const inProgress = [
      "goods received pending replacement to be sent",
      "Goods Received and being assessed",
      "Goods assessed and require additional payment (if applicable)",
      "Goods received and under repair",
      "Quote Paid - Under Repair",
    ].map((element) => element.toLowerCase());

    const completed = ["Completed", "service completed", "Refund processed", "Spare Parts Dispatched, Issue Resolved"].map((element) =>
      element.toLowerCase()
    );
    const closed = ["Not approved", "declined", "did not proceed"].map((element) => element.toLowerCase());

    if (pending.includes(status.toLowerCase())) res = "Pending";
    else if (inProgress.includes(status.toLowerCase())) res = "In-Progress";
    else if (completed.includes(status.toLowerCase())) res = "Completed";
    else if (closed.includes(status.toLowerCase())) res = "Closed";
    return res;
  };

  handleServiceCenterClick = () => {};
  render() {
    const rows = this.props.data;
    const headCells = this.props.head;
    Modal.setAppElement("body");
    const that = this;
    return (
      <div>
        {rows.length === 0 && <div className="m-3">Ticket is not found</div>}
        <TableContainer>
          <MaterialTable aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
            <EnhancedTableHead
              optionServiceCenterHeader={this.props.optionServiceCenterHeader}
              order={this.props.order}
              orderBy={this.props.orderBy}
              onRequestSort={this.handleRequestSort}
              headCells={this.props.head}
              serviceCenters={this.props.serviceCenters}
              statusSelectors={this.props.statusSelectors}
              statusSelector={this.props.statusSelector}
              filterStatus={(val) => this.props.filteredStatus(val)}
              employee={this.props.employee}
              assignedTo={this.props.assignedTo}
              filterAssignTo={this.props.filterAssignTo}
              filterServiceCenter={(val) => this.props.filterServiceCenter(val)}
              serviceCenter={this.props.serviceCenter}
            />

            <TableBody>
              {rows.length > 0 &&
                rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const inDueDate = moment(row["due_date"]).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD");
                  const filterArchived = referenceLibrary.statusClosed.map((element) => {
                    return element.toLowerCase();
                  });

                  const inActiveStatus = filterArchived.includes(row["status"].toLowerCase());
                  const optionServiceCenters = this.props.serviceCenters?.map((s) => {
                    return { value: s.id, label: s.name };
                  });
                  // const optionServiceCenters = this.props.serviceCenterCentrals?.find(s=>s.id===this.state.serviceCenterCentralId).map((s) => {
                  //   return { value: s.id, label: s.name };
                  // });

                  const selectedServiceCenter = this.props.serviceCenters.find((x) => x.id === row?.service_center);

                  let assignToOption = [];
                  if (this.props?.employee) {
                    assignToOption = this.props?.employee
                      ?.filter((x) => x.user_role === "Staff")
                      ?.map((s) => {
                        return { value: s.id, label: s.name + " - " + s.user_role };
                      });
                    if (row.service_center) {
                      const assignToOptionSC = this.props?.employee
                        ?.filter((x) => x.service_center.map((x) => x.id).includes(row.service_center))
                        ?.map((s) => {
                          return { value: s.id, label: s.name + " - " + s.user_role };
                        });

                      const assignToOptionT1 = [...assignToOption, ...assignToOptionSC];

                      let assignToT2 = [];
                      // const arrAssignValues = assignToOptionT1.map((x) => x.value);
                      assignToOptionT1.forEach((a) => {
                        if (!assignToT2.map((x) => x.value).includes(a.value)) assignToT2.push(a);
                      });
                      assignToOption = assignToT2;
                    }

                    // if (row?.service_center) {
                    //   assignToOption = this.props?.employee
                    //     ?.filter((x) => x.service_center.map((x) => x.id).includes(row.service_center) || (x.user_role = "Staff"))
                    //     ?.map((s) => {
                    //       return { value: s.id, label: s.name + " - " + s.user_role };
                    //     });
                    // } else {
                    //   assignToOption = this.props?.employee
                    //     ?.filter((x) => x.user_role === "Staff")
                    //     ?.map((s) => {
                    //       return { value: s.id, label: s.name + " - " + s.user_role };
                    //     });
                    // }
                  }

                  const optionEmployees = selectedServiceCenter?.staff?.map((s) => {
                    return { value: s.employee, label: s.name };
                  });

                  const valueAssignTo =
                    optionEmployees?.find((x) => x.value === row?.assigned_to) ||
                    this.props.employee
                      ?.map((s) => {
                        return { value: s.id, label: `${s.name} - ${s.user_role}` };
                      })
                      ?.find((x) => x.value === row?.assigned_to);

                  return (
                    <TableRow tabIndex={-1} key={labelId} className={`${inDueDate || inActiveStatus ? "in-due-date" : "over-due-date"}`}>
                      {/* Loop through head to get data order */}
                      {headCells.map((item, i) => {
                        const idx = index.toString() + i.toString();
                        if (item.id === "assigned_to") {
                          return (
                            <TableCell
                              key={idx}
                              style={{
                                width: "170px",
                                wordWrap: "break-word",
                                fontSize: "0.8rem",
                              }}
                            >
                              <small>
                                <Select
                                  data-testid="assign-to-select"
                                  value={valueAssignTo}
                                  isSearchable={true}
                                  options={assignToOption}
                                  onChange={(e) => this.handleDropDownAssignTo(e, row.id)}
                                />
                              </small>
                            </TableCell>
                          );
                        } else if (item.id === "service_center") {
                          const valueServiceCenter = optionServiceCenters.find((x) => x.value === item?.valueDisplay(row[item?.id])) || "";
                          const valueServiceCenterCentral =
                            this.props.optionServiceCenterCentrals?.find((x) => x.value === item?.valueDisplay(row["service_center_central"])) || "";
                          return (
                            <TableCell
                              key={idx}
                              sx={{
                                maxWidth: "100px",
                                width: "fitContent",
                                wordWrap: "break-word",
                                paddingLeft: "15px",
                              }}
                              onClick={() => {
                                const optionSC = this.props.serviceCenterCentrals?.find((x) => x.id === valueServiceCenterCentral.value);

                                const optionServiceCenter = optionSC?.service_centers?.map((c) => {
                                  return { value: c.id, label: c.name };
                                });
                                this.setState({
                                  ticketID: item?.valueDisplay(row["id"]),
                                  showServiceCenterModal: true,
                                  serviceCenterCentralId: valueServiceCenterCentral.value,
                                  serviceCenterId: valueServiceCenter.value,
                                  optionServiceCenters: optionServiceCenter,
                                  serviceCenterValue: this.props.serviceCenters
                                    ?.map((s) => {
                                      return { value: s.id, label: s.name };
                                    })
                                    ?.find((s) => s.value === valueServiceCenter.value),
                                });
                              }}
                            >
                              <button className="bg-white border rounded p-1 w-100">
                                <div className="text-truncate">
                                  <small>{valueServiceCenterCentral.label || "-"}</small>
                                </div>
                                <div className="text-truncate">
                                  <small>{valueServiceCenter.label || "-"} </small>
                                </div>
                              </button>
                            </TableCell>
                          );
                        }

                        if (item.id === "ticket_type") {
                          let value = item?.valueDisplay(row[item?.id]);
                          const capitalizedStr = value.charAt(0).toUpperCase() + value.slice(1);
                          return (
                            <TableCell
                              key={idx}
                              sx={{
                                maxWidth: "100px",
                                width: "fitContent",
                                wordWrap: "break-word",
                                paddingLeft: "15px",
                              }}
                            >
                              {capitalizedStr === "Out_of_warranty" ? "Out of Waranty (Service)" : "Warranty"}
                            </TableCell>
                          );
                        } else if (item.id === "ticket_number") {
                          return (
                            <TableCell
                              key={idx}
                              style={{
                                maxWidth: "200px",
                                wordWrap: "break-word",
                              }}
                            >
                              <Link to={"/warranty/" + row.id}>{item?.valueDisplay(row[item?.id])}</Link>
                            </TableCell>
                          );
                        } else if (item.id === "device_name") {
                          return (
                            <TableCell
                              key={idx}
                              style={{
                                maxWidth: "200px",
                                wordWrap: "break-word",
                              }}
                            >
                              <div>{item?.valueDisplay(row[item?.id])}</div>
                              <div>{`[${item?.valueDisplay(row["serial_number"])}]`}</div>
                            </TableCell>
                          );
                        } else if (item.id === "status") {
                          let value = item?.valueDisplay(row[item?.id]);
                          return (
                            <TableCell
                              title={value}
                              key={idx}
                              style={{
                                width: "100px",
                                wordWrap: "break-word",
                              }}
                            >
                              <div className={`status-pill pill-${this.getStatusProperty(value).toLowerCase()}`}>
                                {this.getStatusProperty(value).replace("-", " ") || value}
                              </div>
                            </TableCell>
                          );
                        } else if (item.id === "owner_name") {
                          return (
                            <TableCell
                              key={idx}
                              style={{
                                width: "150px",
                                wordWrap: "break-word",
                              }}
                            >
                              {this.props.role === "Admin" ? (
                                <Link to={"/customer-detail/" + row.customer_id}>{item?.valueDisplay(row[item?.id])}</Link>
                              ) : (
                                item?.valueDisplay(row[item?.id])
                              )}
                            </TableCell>
                          );
                        } else if (item.id === "due_date") {
                          return (
                            <TableCell
                              key={idx}
                              style={{
                                maxWidth: "100px",
                                wordWrap: "break-word",
                              }}
                            >
                              {inActiveStatus ? (
                                <span>{`  ${moment(row[item?.id]).format("DD-MM-YYYY")}`}</span>
                              ) : (
                                <DatePicker
                                  // disabled={this.props.role === "Service"}
                                  wrapperClassName="due-table-datepicker"
                                  id={`due_date_${row["id"]}`}
                                  className="rounded border border-light p-2"
                                  selected={new Date(item?.valueDisplay(row[item?.id]))}
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(val) =>
                                    this.setState({
                                      due_date: moment(val).format("YYYY-MM-DD"),
                                      showDueDateModal: true,
                                      ticketID: row["id"],
                                    })
                                  }
                                />
                              )}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              key={idx}
                              style={
                                item?.width
                                  ? {
                                      width: item?.width,
                                      wordWrap: "break-word",
                                    }
                                  : {
                                      width: "100px",
                                      wordWrap: "break-word",
                                    }
                              }
                            >
                              {item?.valueDisplay(row[item?.id])}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </MaterialTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25, 100]}
          component="div"
          count={this.props.totalRows}
          rowsPerPage={this.props.rowsPerPage}
          page={this.props.pageNumber - 1}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />

        <Modal
          isOpen={this.state.showAssignTo}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"confirm-modal"}
        >
          <div className="card">
            <button onClick={() => this.setState({ showAssignTo: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">Ticket Assignment</div>
            <form onSubmit={this.handleSubmitAssign}>
              <div className={"card-body"}>
                <span>Confirm assign ticket to {this.state.curEmployeeName}?</span>
              </div>
              <div className="card-footer">
                <button type="submit" className={"btn btn-outline-primary mr-3"}>
                  Assign
                </button>
                <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showAssignTo: false })}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showServiceCenter}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"confirm-modal"}
        >
          <div className="card">
            <button onClick={() => this.setState({ showServiceCenter: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">Change Service Center</div>
            <form onSubmit={this.handleSubmitServiceCenter}>
              <div className={"card-body"}>
                <span>Confirm change service center ticket to {this.state.curServiceCenterName}?</span>
              </div>
              <div className="card-footer">
                <button type="submit" className={"btn btn-outline-primary mr-3"}>
                  Change
                </button>
                <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showServiceCenter: false })}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.showModalResetPassword}
          contentLabel={"Reset Password"}
          className={"confirm-modal confirm-modal-big-approve warranty-decline-modal"}
        >
          <button onClick={() => this.setState({ showModalResetPassword: false })} className={"confirm-modal-close"}>
            <MdClose />
          </button>
          <form>
            <div className={"approveDeclineSet confirmApproveDeclineSet"}>
              <div className={"form-field"}>
                <div className="mb-3">Are you sure you want to send a password reset to {this.state.emailForReset}?</div>
                <button type="button" className={"approve confirmDecline"} onClick={this.handleResetPassword}>
                  Reset
                </button>
                <button type={"button"} className={"neutral confirmNeutral"} onClick={() => this.setState({ showModalResetPassword: false })}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={this.state.showModalResetPasswordSuccess}
          contentLabel={"Reset Password"}
          className={"confirm-modal confirm-modal-big-approve warranty-decline-modal"}
        >
          <form>
            <div className={"approveDeclineSet confirmApproveDeclineSet"}>
              <div className={"form-field"}>
                <div className="mb-3">The reset password email is sent to {this.state.emailForReset}</div>
                <button
                  type={"button"}
                  className={"neutral confirmNeutral"}
                  onClick={() =>
                    this.setState({
                      showModalResetPasswordSuccess: false,
                      showModalResetPassword: false,
                    })
                  }
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </Modal>

        {/* Modal for confirm status ticket type */}
        <Modal isOpen={this.state.modalStatus} contentLabel={"Set as Out of Warranty"} className={"confirm-modal"}>
          <form onSubmit={this.handleChangeStatusTicketType}>
            <div className={"approveDeclineSet confirmApproveDeclineSet"}>
              <div className={"form-field"}>
                <div className="mb-3">Change Status to {this.state.statusTicketType}?</div>

                <button type="submit" className={"approve confirmDecline"}>
                  Confirm
                </button>

                <button type={"button"} className={"neutral confirmNeutral"} onClick={this.handleCancel}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={this.state.showDueDateModal}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"confirm-modal "}
        >
          <div className="card">
            <div className="card-header">Due date change</div>
            <div className="card-body">Are you sure you want to change the due date?</div>
            <div className="card-footer">
              <button type="button" className={"btn btn-outline-primary mr-3"} onClick={() => that.changeDueDate()}>
                Change
              </button>
              <button
                type={"button"}
                className={"btn btn-outline-secondary"}
                onClick={() => this.setState({ showDueDateModal: false, dueDateReason: "" })}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showServiceCenterModal}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"confirm-modal "}
        >
          <div className="card">
            <div className="card-header">Service center change</div>
            <div className="card-body  text-left">
              <div className={"single-warranty-details"}>
                <p className="text-center text-danger">
                  {!this.state.serviceCenterCentralId && "Once this service centre has been selected, only a Super Admin can change it"}
                </p>
                <b>Service Center</b>
                <div className="w-auto mb-3">
                  <Select
                    data-testid="assign-to-select"
                    value={this.props.optionServiceCenterCentrals?.find((s) => s.value === this.state.serviceCenterCentralId)}
                    options={this.props.optionServiceCenterCentrals}
                    isSearchable={true}
                    isDisabled={!(this.props?.role === "Admin" || !this.state.serviceCenterCentralId)}
                    // onInputChange={(val) => this.handleSearchServiceCenter(val)}
                    onChange={(obj) => {
                      this.handleDropDownServiceCenterCentral(obj);
                    }}

                    // onInputChange={(val) => this.searchLocation("suburb", val, this.state.stateVal)}
                  />
                </div>

                <div className={"single-warranty-details"}>
                  <b>Service Center Location</b>
                  <div className="w-auto ">
                    <Select
                      data-testid="assign-to-select"
                      value={this.state.serviceCenterValue}
                      options={this.state.optionServiceCenters}
                      isSearchable={true}
                      isDisabled={!(this.props?.role === "Admin" || this.props?.role === "Service Center Admin")}
                      // onInputChange={(val) => this.handleSearchServiceCenter(val)}
                      onChange={(obj) => {
                        this.setState({
                          serviceCenterValue: this.props.serviceCenters
                            ?.map((s) => {
                              return { value: s.id, label: s.name };
                            })
                            ?.find((s) => s.value === obj.value),
                        });
                        this.handleDropDownServiceCenter(obj);
                      }}

                      // onInputChange={(val) => this.searchLocation("suburb", val, this.state.stateVal)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button
                type={"button"}
                className={"btn btn-outline-secondary"}
                onClick={() => {
                  this.setState({ showServiceCenterModal: false });
                  this.props.retrieveWarranty(this.ticketId);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
