import React, { Component } from "react";
import "./Navbar.css";
import Modal from "react-modal";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import logo from "../../img/fav_32x32.png";
import logo_r from "../../img/logo_r32.png";
import { MdExpandMore } from "react-icons/md";
import DatePicker from "react-datepicker";
import { Link, NavLink, withRouter } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import utilityFunctions from "../../store/utilityFunctions";
import moment from "moment";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      width: window.innerWidth,
      brands: [],
      isAdmin: false,
      isCommercial: false,
      isStaff: false,

      isSCA: false,
      selectedBrandID: utilityFunctions.getCookie("brandId") || 0,
      mylogo: logo,
    };
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.handleSettingsLinkClick = this.handleSettingsLinkClick.bind(this);
    this.handleSettingsLinkClick = this.handleSettingsLinkClick.bind(this);
    this.handleSelectedBrand = this.handleSelectedBrand.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.retrieveBrands();
    const userInfoJson = localStorage.getItem("userInfo") || utilityFunctions.getCookie("userInfo");
    const userInfo = userInfoJson && JSON.parse(userInfoJson);
    const role = userInfo.user.role;

    const isPTC = !!userInfo.user.service_center.find((s) => s.name.includes("PTC"));
    const isAdmin = role === "Admin";
    const isSuperAdmin = ["william@treeclouds.com", "alfred@subemo.com"].includes(userInfo.user.email);
    const isApprover = role === "Approver";
    const isSCA = role === "Service Center Admin" && isPTC;
    const isStaff = role === "Staff";
    const isContractor = role === "Contractor";
    this.setState({ isAdmin, isSCA, isStaff, isApprover, isContractor, isSuperAdmin });
  
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  retrieveBrands = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "brands/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ brands: res });
        const brandId =
          config.country == "NZ" ? res.find((brand) => brand.name.includes("NZ")).id : res.find((brand) => !brand.name.includes("NZ")).id;
        this.props.handleSelectedBrandID(brandId);
        if (res[0].id === "2") this.setState({ mylogo: logo });
        else if (res[0].id === "1") this.setState({ mylogo: logo_r });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  resize = () => {
    this.setState({ width: window.innerWidth });
  };

  openMenu() {
    if (!this.state.showMenu) {
      this.setState({ showMenu: true });
    }
  }
  closeMenu() {
    if (this.state.showMenu) {
      this.setState({ showMenu: false });
    }
  }
  handleSettingsLinkClick(linkToGoTo) {
    this.props.history.push(linkToGoTo);
  }

  handleSelectedBrand(e) {
    let value = e.target.value;
    this.props.handleSelectedBrandID(value);
    if (value === "2") this.setState({ mylogo: logo });
    else if (value === "1") this.setState({ mylogo: logo_r });
  }

  handleSubmit = (e) => {
    if (this.state.fromDate > this.state.toDate) {
      alert("From date can not be greater than to date");
      return;
    }
    this.setState({ loading: true });
    e.preventDefault();
    console.log(this.state.addComment);

    const fromDate = this.state.fromDate ? `from_date=${moment(this.state.fromDate).format("YYYY-MM-DD")}` : "";
    const toDate = this.state.toDate ? `${this.state.fromDate ? "&" : ""}to_date=${moment(this.state.toDate).format("YYYY-MM-DD")}` : "";

    const url = `${config.backendServer}report/export_activity_ticket/?${fromDate}${toDate}`;

    this.props
      .protectedFetch(url, "GET", null, (res) => {
        return res; // Do everything in the 'then'
      })
      .then((res) => {
        if (res.ok) {
          res.blob().then((res) => {
            const blob = res;
            blob.name = "Time_Machine_" + moment(this.state.toDate).format("YYYY-MM-DD");
            utilityFunctions.downloadFileAutomatically(blob, res.type);
            this.setState({ showModalReport: false });
          });
        }
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false, showFilterModal: false });
        this.setState({ showModalReport: false });
      });
  };

  render() {
    let navbarLinks = {
      Tickets: "/",
      Customer: "/customer-list",
      Inventories: "/inventory",
      Service_Centers: "/service-centers",
    };
    if (this.state.isStaff || this.state.isApprover) {
      navbarLinks = {
        Tickets: "/tickets",
        Customers: "/customer-list",
        Inventories: "/inventory",
        Service_Centers: "/service-centers",
        Stores: "/stores",
      };
    } else if (this.state.isAdmin) {
      navbarLinks = {
        Home: "/",
        Tickets: "/tickets",
        Customers: "/customer-list",
        Inventories: "/inventory",
        Devices: "/device",
        Service_Centers: "/service-centers",
        Stores: "/stores",
      };
    } else if (this.state.isSCA) {
      navbarLinks = {
        Home: "/",
        Tickets: "/tickets",
        Customers: "/customer-list",
        Inventories: "/inventory",
        Service_Centers: "/service-centers",
      };
    } else if (this.state.isContractor) {
      navbarLinks = {
        Tickets: "/ticket-search",
      };
    }



    const additionalSettings = {};
    const handleSettingsLinkClick = this.handleSettingsLinkClick;

    if (this.state.width > 850) {
      //Desktop menu
      return (
        <div
          className={"NavbarAboveContainer" /* To set max-width on navbarContainer while keeping a white background */}
          tabIndex="0"
          onClick={this.closeMenu}
          onBlur={this.closeMenu /*Tab Index and blur used so that clicks outside of div will cause menu to close*/}
        >
          <div className="NavbarContainer">
            <Link to={"/"}>
              <img className={"NavbarLogo"} src={this.state.mylogo} alt={"logo"} />
            </Link>

            <div className={"NavbarLinkArea"}></div>
            <div className={"NavbarLinkArea"}>
              {Object.keys(navbarLinks).map(function (linkName) {
                return (
                  <NavLink key={"navbar" + linkName} to={navbarLinks[linkName]} className={"NavbarLink"} activeClassName="NavbarLinkActive" exact>
                    {linkName.replace("_", " ")}
                  </NavLink>
                );
              })}
            </div>

            <div className={"NavbarDropdown"} onClick={this.openMenu}>
              <div>
                {this.props.userInfo.user.name || this.props.userInfo.user.email}
                <MdExpandMore className={"NavbarDropdownIcon"} />
              </div>
            </div>
            {this.state.showMenu && (
              <div onClick={(e) => e.stopPropagation() /*Stop clicks in menu causing clicks above menu*/} className={"NavbarMenuContainer"}>
                {/* Options for settings comes from props*/}
                {Object.keys(additionalSettings).map(function (linkName) {
                  return (
                    <div
                      onClick={() => handleSettingsLinkClick(additionalSettings[linkName])}
                      //Mouse down is needed for IE11 bug - onBlur triggers before click - so using mousedown
                      onMouseDown={() => handleSettingsLinkClick(additionalSettings[linkName])}
                      className={"NavbarMenuItem"}
                      key={"settings" + linkName}
                    >
                      {linkName}
                    </div>
                  );
                })}

                {this.state.isAdmin ? (
                  <div>
                    <div className={"NavbarMenuItem"} onClick={() => handleSettingsLinkClick("/manage-staff")}>
                      Manage staff
                    </div>
                    {this.state.isSuperAdmin && (
                      <div className={"NavbarMenuItem"} onClick={() => this.setState({ showModalReport: true })}>
                        Time Machine Report
                      </div>
                    )}
                  </div>
                ) : null}
                <div
                  onClick={this.props.logout}
                  //Mouse down is needed for IE11 bug - onBlur triggers before click - so using mousedown
                  onMouseDown={this.props.logout}
                  className={"NavbarMenuItem"}
                >
                  Sign Out
                </div>
              </div>
            )}
          </div>
          <Modal isOpen={this.state.showModalReport} className={"input-modal"}>
            <div className="card">
              <div className="card-header">Time Machine Report</div>
              <div className={"card-body"}>
                <div className="row p-2">
                  <div className="col-5">
                    <span className="p-2">From Date:</span>
                    <DatePicker
                      id="fromDate"
                      maxDate={moment().toDate()}
                      selected={this.state.fromDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(val) =>
                        this.setState({
                          fromDate: val,
                        })
                      }
                    />
                  </div>
                  <div className="col-5">
                    <span className="p-2">To Date:</span>
                    <DatePicker
                      id="toDate"
                      maxDate={moment().toDate()}
                      selected={this.state.toDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(val) =>
                        this.setState({
                          toDate: val,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button onClick={this.handleSubmit} className="btn btn-primary btm-sm mr-2">
                  Export
                </button>
                <button onClick={() => this.setState({ showModalReport: false })} className="btn btn-secondary btm-sm">
                  Close
                </button>
              </div>
            </div>
          </Modal>
        </div>
      );
    } else {
      return (
        <div className={"NavbarAboveContainer" /* To set max-width on navbarContainer while keeping a white background */}>
          <Menu>
            {Object.keys(navbarLinks).map(function (linkName) {
              return (
                <NavLink key={"navbar" + linkName} to={navbarLinks[linkName]} className={"NavbarLink"} activeClassName="NavbarLinkActive" exact>
                  {linkName}
                </NavLink>
              );
            })}
            {Object.keys(additionalSettings).map(function (linkName) {
              return (
                <NavLink
                  key={"navbar" + linkName}
                  to={additionalSettings[linkName]}
                  className={"NavbarLink"}
                  activeClassName="NavbarLinkActive"
                  exact
                >
                  {linkName}
                </NavLink>
              );
            })}
            <div onClick={() => handleSettingsLinkClick("/manage-staff")} className={"NavbarLink"}>
              Manage Staff
            </div>

            <div onClick={this.props.logout} className={"NavbarLink"}>
              Sign Out
            </div>
          </Menu>
          <div className="NavbarContainer">
            <Link to={"/"}>
              <img alt="logo" className={"NavbarLogo"} src={logo} height={16} />
            </Link>
          </div>
        </div>
      );
    }
  }
}
export default withRouter(Navbar); //Adding router to this so I can access 'history' prop, which is used to redirect profile, which is done because setting a link in the dropdown appears to never trigger some reason
