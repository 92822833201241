import React, { Component } from "react";
import Modal from "react-modal";
// import { Table as MaterialTable } from "@material-ui/core";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import utilityFunctions from "../../store/utilityFunctions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createBrowserHistory } from "history";

export default class ServiceCenterAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellers: [],
      filteredSellers: [],
      filteredStores: [],
      selectedSeller: null,
      sellerId: null,
      newSeller: null,
      stores: [],
      selectedStore: null,
      store: null,
      storeId: null,
      showSellerAdd: false,
      showStoreAdd: false,
      showSellerEdit: false,
      showStoreEdit: false,
      showDeleteSeller: false,
      showDeleteStore: false,
      deleteSeller: null,
      deleteStore: null,
      searchSeller: null,
      role: this.props.userInfo.user.role,
    };
  }

  componentDidMount() {
    if (["Commercial Admin", "Commercial Staff"].includes(this.props.userInfo.user.role)) this.retrieveStore();
    else this.retrieveSeller();
  }

  retrieveSeller() {
    this.setState({
      isLoading: true,
      // deviceId: brandDevice[0]?.id,
    });
    this.props
      .protectedFetch(config.backendServer + "sellers/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          sellers: res,
          filteredSellers: res,
          isLoading: false,
          seller: !this.state.seller && res[0].name,
          sellerId: !this.state.seller && res[0].id,
          selectedSeller: !this.state.selectedSeller && res[0],
          // stores: res[0].store_locations,
          // deviceId: brandDevice[0]?.id,
        });
        this.retrieveStore(res[0].id);
      })
      .catch((err) => console.log(err));
  }

  updateSeller(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
      // deviceId: brandDevice[0]?.id,
    });
    const payload = {
      name: this.state.newSeller,
    };
    utilityFunctions
      .protectedFetch(config.backendServer + "seller/" + this.state.selectedSeller.id + "/", "PATCH", payload, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          isLoading: false,
          seller: res.name,
          selectedSeller: res,
          showSellerEdit: false,
          stores: res.store_locations,
          // deviceId: brandDevice[0]?.id,
        });
        this.retrieveSeller();
        this.retrieveStore(res[0].id);
        // this.retrieveStore(res[0].name);
      })
      .catch((err) => console.log(err));
  }
  addSeller(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
      // deviceId: brandDevice[0]?.id,
    });
    const payload = {
      name: this.state.newSeller,
    };
    utilityFunctions
      .protectedFetch(config.backendServer + "seller/", "POST", payload, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          isLoading: false,
          seller: res.name,
          showSellerAdd: false,
          newSeller: null,
          // stores: res.store_locations,
          // deviceId: brandDevice[0]?.id,
        });
        this.retrieveSeller();
        // this.retrieveStore(res[0].name);
      })
      .catch((err) => {
        alert("Store with this name already exists.");
        console.log(err);
      });
  }

  deleteSeller = () => {
    const userInfo = localStorage.getItem("userInfo") || utilityFunctions.getCookie("userInfo");

    //state jika di refresh akan kembali null, jadi ambil data dari localstorage atau cookies solusinya
    const token = userInfo?.access_token || JSON.parse(userInfo)?.access_token;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    this.setState({ loading: true, showDeleteModal: false });
    const url = config.backendServer + "seller/" + this.state.selectedSeller.id + "/";

    fetch(url, options)
      .then((resp) => {
        if (resp.status === 403) alert("Cannot delete store. Device is already registered by at least one user(s)");
        else if (resp.status === 204) alert("Delete success");
        else alert("status:" + resp.status);

        this.setState({ showDeleteSeller: false, deleteStore: null });
        this.retrieveSeller();
      })
      .catch((err) => {
        alert(err);
        this.setState({ showDelete: false });
      });
  };
  updateStore(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
      // deviceId: brandDevice[0]?.id,
    });
    const payload = {
      name: this.state.newStore,
    };
    utilityFunctions
      .protectedFetch(
        config.backendServer + "seller/" + this.state.selectedSeller.id + "/store_location/" + this.state.selectedStore.id + "/",
        "PATCH",
        payload,
        errorFunctions.checkResponseThenJson
      )
      .then((res) => {
        this.setState({
          isLoading: false,
          seller: res.name,
          showStore: false,
          // deviceId: brandDevice[0]?.id,
        });
        this.retrieveStore(this.state.selectedSeller.id);
      })
      .catch((err) => console.log(err));
    this.setState({
      showStoreEdit: false,
    });
  }
  addStore(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
      // deviceId: brandDevice[0]?.id,
    });
    const payload = {
      name: this.state.newStore,
    };
    utilityFunctions
      .protectedFetch(
        config.backendServer + "seller/" + this.state.sellerId + "/store_location/",
        "POST",
        payload,
        errorFunctions.checkResponseThenJson
      )
      .then((res) => {
        this.setState({
          isLoading: false,
          store: res.name,
          newStore: null,
          showStore: false,

          // deviceId: brandDevice[0]?.id,
        });
        // this.retrieveSeller();
        this.retrieveStore(this.state.sellerId);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
    this.setState({
      showStoreAdd: false,
    });
  }

  retrieveStore(seller) {
    this.setState({
      isLoading: true,
      // deviceId: brandDevice[0]?.id,
    });

    const url = seller ? "seller/" + seller + "/store_locations/?country=" + config.country : "store_locations/?country=" + config.country;
    utilityFunctions
      .protectedFetch(config.backendServer + url, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const sortRes = res.sort((a, b) => {
          return a.name > b.name;
        });
        this.setState({
          stores: sortRes,
          filteredStores: sortRes,
          isLoading: false,
          // deviceId: brandDevice[0]?.id,
        });
      })
      .catch((err) => console.log(err));
  }
  // deleteStore() {

  deleteStore = () => {
    const userInfo = localStorage.getItem("userInfo") || utilityFunctions.getCookie("userInfo");

    //state jika di refresh akan kembali null, jadi ambil data dari localstorage atau cookies solusinya
    const token = userInfo?.access_token || JSON.parse(userInfo)?.access_token;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    this.setState({ loading: true, showDeleteModal: false });
    const url = config.backendServer + "seller/" + this.state.selectedSeller.id + "/store_location/" + this.state.selectedStore.id + "/";

    fetch(url, options)
      .then((resp) => {
        if (resp.status === 403) alert("Cannot delete store. Device is already registered by at least one user(s)");
        else if (resp.status === 204) alert("Delete success");
        else alert("status:" + resp.status);

        this.setState({ showDeleteStore: false, deleteStore: null });
        this.retrieveStore(this.state.selectedSeller.id);
        // this.props.reloadDevice();
      })
      .catch((err) => {
        alert(err);
        this.setState({ showDelete: false });
      });
  };

  handleSellerClick(seller) {
    this.setState({ seller: seller.name, selectedSeller: seller, sellerId: seller.id, deleteItem: seller });
    this.retrieveStore(seller.id);
  }

  handleStoreRowClick(store) {
    this.setState({ store: store, deleteItem: store });
    // this.retrieveStore(seller);
  }
  handleStoreLocationClick = (id) => {
    const history = createBrowserHistory({ forceRefresh: true });
    history.push(`/commercial/${id}`);
    // alert("row");
  };

  handleEditSeller(seller) {
    this.setState({ seller: seller, showSeller: true });
  }
  handleDeleteSeller(selectedSeller) {
    this.setState({ selectedSeller: selectedSeller, showDeleteSeller: true, deleteSeller: selectedSeller });
  }
  handleDeleteStore(store) {
    this.setState({ selectedStore: store, showDeleteStore: true, deleteStore: store });
  }

  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };

  handleSearchSeller = (e) => {
    this.handleInputChange(e);
    const filter = e.target.value.toLowerCase();
    if (e.target.value.length > 2) {
      const filteredSellers = this.state.sellers && this.state.sellers.filter((s) => s.name.toLowerCase().includes(filter));
      this.setState({ filteredSellers });
    } else this.setState({ filteredSellers: this.state.sellers });
  };

  handleSearchStore = (e) => {
    this.handleInputChange(e);
    const filter = e.target.value.toLowerCase();
    if (e.target.value.length > 2) {
      const filteredStores = this.state.stores && this.state.stores.filter((s) => s.name.toLowerCase().includes(filter));
      this.setState({ filteredStores });
    } else this.setState({ filteredStores: this.state.stores });
  };

  handleAddSeller = (e) => {
    if (this.state.newSeller) {
      if (this.state.selectedSeller) this.updateSeller(e);
      else this.addSeller(e);
    } else alert("Store can't be empty");
  };

  handleDeleteConfirmation = (e) => {
    if (this.state.deleteSeller) this.deleteSeller(e);
    else if (this.state.deleteStore) this.deleteStore(e);
  };
  render() {
    return (
      <div className={"page"}>
        <div style={{ width: "100px", borderBottom: "1px solid black", textAlign: "center" }}>
          <h5>Stores</h5>
        </div>
        <div className="d-flex">
          {["Admin", "Staff"].includes(this.props.userInfo.user.role) && (
            <div className="col">
              <div className="mt-1">
                <div className="input-group my-2">
                  <input
                    type="text"
                    placeholder="Search stores"
                    data-testid={"searchStore"}
                    id="searchSeller"
                    value={this.state.searchSeller}
                    className="form-control"
                    onChange={(e) => this.handleSearchSeller(e)}
                  />
                  <div className="input-group-prepend">
                    <span className="form-control ">
                      <i className="fa fa-search form-control-icon"></i>
                    </span>
                  </div>
                </div>

                <TableContainer>
                  <Table aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table" component={Paper}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Store</TableCell>

                        <TableCell className="text-end">
                          <button
                            className="k-btn k-btn-primary k-btn-sm"
                            onClick={() => this.setState({ seller: null, newSeller: null, showSellerAdd: true })}
                          >
                            Add
                          </button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.filteredSellers?.map((s, i) => {
                        return (
                          <TableRow key={i} className={this.state.selectedSeller.name === s.name && "font-weight-bold bg-light"}>
                            <TableCell
                              style={{
                                // width: "430px",
                                wordWrap: "break-word",
                                cursor: "pointer",
                              }}
                              onClick={() => this.handleSellerClick(s)}
                            >
                              {s.name}
                            </TableCell>

                            <TableCell
                              style={{
                                width: "200px",
                                wordWrap: "break-word",
                              }}
                            >
                              <div>
                                <button
                                  className={"btn btn-outline-primary btn-sm mr-1 "}
                                  onClick={() => this.setState({ seller: s.name, selectedSeller: s, newSeller: s.name, showSellerEdit: true })}
                                >
                                  Edit
                                </button>
                                <button className={"btn btn-outline-danger btn-sm "} onClick={() => this.handleDeleteSeller(s)}>
                                  Delete
                                </button>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}
          <div className="col">
            <div className="mt-1">
              <div className="input-group my-2">
                <input
                  type="text"
                  placeholder="Search Store Location"
                  data-testid={"refund-amount"}
                  id="searchStore"
                  value={this.state.searchStore}
                  className="form-control"
                  // style={{ height: "100%" }}
                  onChange={(e) => this.handleSearchStore(e)}
                />
                <div className="input-group-prepend">
                  <span className="form-control ">
                    <i className="fa fa-search form-control-icon"></i>
                  </span>
                </div>
              </div>
              <TableContainer sx={{ maxHeight: 600, overflowX: "hidden" }}>
                <Table sx={{ minWidth: 600 }} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table" component={Paper}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "300px" }}>Store Location</TableCell>
                      <TableCell
                        style={{
                          // width: "800px",
                          wordWrap: "break-word",
                          cursor: "pointer",
                        }}
                      >
                        Comm. Users
                      </TableCell>
                      <TableCell className="text-end">
                        {["Admin", "Staff"].includes(this.props.userInfo.user.role) && (
                          <button
                            className="k-btn k-btn-primary k-btn-sm"
                            onClick={() => this.setState({ store: null, newStore: null, showStoreAdd: true })}
                          >
                            Add
                          </button>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.filteredStores.length ? (
                      this.state.filteredStores
                        ?.sort((a, b) => {
                          return b.name - a.name;
                        })
                        .map((s, i) => {
                          return (
                            <TableRow
                              key={i}
                              // onClick={() => this.handleStoreRowClick(s)}
                              className={this.state.store === s.name && "font-weight-bold  bg-light"}
                            >
                              <TableCell
                                style={{
                                  width: "300px",
                                }}
                                // onClick={() => this.handleStoreLocationClick(s.id)}
                              >
                                {s.name}
                              </TableCell>
                              <TableCell
                                style={{
                                  // width: "800px",
                                  wordWrap: "break-word",
                                }}
                              >
                                {s.commercial_user_count || "0"}
                              </TableCell>
                              <TableCell>
                                {["Admin", "Staff"].includes(this.props.userInfo.user.role) && (
                                  <div>
                                    <button
                                      className={"btn btn-outline-primary btn-sm mr-1 "}
                                      onClick={() =>
                                        this.setState({ store: s.name, storeId: s.id, newStore: s.name, showStoreEdit: true, selectedStore: s })
                                      }
                                    >
                                      Edit
                                    </button>
                                    <button className={"btn btn-outline-danger btn-sm "} onClick={() => this.handleDeleteStore(s)}>
                                      Delete
                                    </button>
                                  </div>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell>No data</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.showSellerEdit} contentLabel={"Set as Out of Warranty"} className={"confirm-modal "}>
          <div className="card">
            <div className="card-header">Edit Store</div>
            <div className={"card-body"}>
              <div className="text-start m-3">
                <label className={"single-warranty-details-label "} htmlFor="deviceName">
                  Store Name*
                </label>
                <input
                  type="text"
                  required
                  data-testid={"refund-amount"}
                  id="newSeller"
                  value={this.state.newSeller}
                  className="form-control"
                  style={{ height: "100%" }}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </div>
              <button
                data-testid="confirm-status-ticket"
                type="button"
                className={"btn btn-outline-primary mr-3"}
                onClick={(e) => this.updateSeller(e)}
              >
                Submit
              </button>
              <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showSellerEdit: false })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.showSellerAdd} contentLabel={"Set as Out of Warranty"} className={"confirm-modal "}>
          <div className="card">
            <div className="card-header">Add Store</div>
            <div className={"card-body"}>
              <div className="text-start m-3">
                <label className={"single-warranty-details-label "} htmlFor="deviceName">
                  Store Name*
                </label>
                <input
                  type="text"
                  required
                  data-testid={"refund-amount"}
                  id="newSeller"
                  value={this.state.newSeller}
                  className="form-control"
                  style={{ height: "100%" }}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </div>
              <button data-testid="confirm-status-ticket" type="button" className={"btn btn-outline-primary mr-3"} onClick={(e) => this.addSeller(e)}>
                Submit
              </button>
              <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showSellerAdd: false })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.showStoreAdd} contentLabel={"Set as Out of Warranty"} className={"confirm-modal "}>
          <div className="card">
            <div className="card-header">{this.state.store ? "Edit Store Location" : "Add Store Location"}</div>
            <div className={"card-body"}>
              <div className="text-start m-3">
                <label className={"single-warranty-details-label "} htmlFor="deviceName">
                  Store Location Name*
                </label>
                <input
                  type="text"
                  required
                  data-testid={"refund-amount"}
                  id="newStore"
                  value={this.state.newStore}
                  className="form-control"
                  style={{ height: "100%" }}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </div>
              <button data-testid="confirm-status-ticket" type="button" className={"btn btn-outline-primary mr-3"} onClick={(e) => this.addStore(e)}>
                Submit
              </button>
              <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showStoreAdd: false })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.showStoreEdit} contentLabel={"Set as Out of Warranty"} className={"confirm-modal "}>
          <div className="card">
            <div className="card-header">{this.state.store ? "Edit Store Location" : "Add Store Location"}</div>
            <div className={"card-body"}>
              <div className="text-start m-3">
                <label className={"single-warranty-details-label "} htmlFor="deviceName">
                  Store Location Name*
                </label>
                <input
                  type="text"
                  required
                  data-testid={"refund-amount"}
                  id="newStore"
                  value={this.state.newStore}
                  className="form-control"
                  style={{ height: "100%" }}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </div>
              <button
                data-testid="confirm-status-ticket"
                type="button"
                className={"btn btn-outline-primary mr-3"}
                onClick={(e) => this.updateStore(e)}
              >
                Submit
              </button>
              <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showStoreEdit: false })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.showDeleteSeller} contentLabel={"Set as Out of Warranty"} className={"confirm-modal "}>
          <div className="card">
            <div className="card-header">Delete Confirmation</div>
            <div className={"card-body"}>
              <div className="text-start m-3">{`Are you sure want to delete ${this.state.selectedSeller?.name}?`}</div>
              <button
                data-testid="confirm-status-ticket"
                type="button"
                className={"btn btn-outline-primary mr-3"}
                onClick={() => this.deleteSeller()}
              >
                Delete
              </button>
              <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showDeleteSeller: false })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.showDeleteStore} contentLabel={"Set as Out of Warranty"} className={"confirm-modal "}>
          <div className="card">
            <div className="card-header">Delete Confirmation</div>
            <div className={"card-body"}>
              <div className="text-start m-3">{`Are you sure want to delete ${this.state.selectedStore?.name}?`}</div>
              <button data-testid="confirm-status-ticket" type="button" className={"btn btn-outline-primary mr-3"} onClick={() => this.deleteStore()}>
                Delete
              </button>
              <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showDeleteStore: false })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
